export default {
  hostApi: process.env.BACKEND_HOST,
  gfAPI: process.env.GF_HOST,
  fileExportsAPI: process.env.FILE_EXPORT_API,
  metricsAPI: process.env.METRICS_API,
  queryAPI: process.env.QUERY_API,
  dashboardAPI: process.env.DASHBOARD_API,
  dashBFFAPI: process.env.DASH_BFF_API,
  whiteLabelCustomHostAPI: process.env.WHITE_LABEL_CUSTOM_HOST_API,
  cancelledLetterAPI: process.env.CANCELLED_LETTER_API,
  clientsAPI: process.env.CLIENTS_API,
  isStaging: process.env.IS_STAGING,
  domain: process.env.DOMAIN,
  cognitoRegion: process.env.COGNITO_REGION,
  cognitoUserPoolId: process.env.APP_SECRET_COGNITO_USER_POOL_ID,
  cognitoUserPoolClientId: process.env.APP_SECRET_COGNITO_USER_POOL_CLIENT_ID,
  datadogApplicationId: process.env.APP_SECRET_DATADOG_APPLICATION_ID,
  datadogClientToken: process.env.APP_SECRET_DATADOG_CLIENT_TOKEN,
  env: process.env.ENV,
  XAPIKey: process.env.APP_SECRET_X_API_KEY
}

import axios from 'axios'
import appConfig from './appConfig'
import qs from 'qs'
import { VersioningStrategy, withVersioning } from 'src/Utils/AxiosApiVersioning'
import {
  fulfillMiddleware,
  rejectMiddleware,
  insertHeaders,
  insertSellerAndMarketplaceContext
} from 'src/Utils/requestUtils'

const axiosInstance = axios.create({
  baseURL: `${appConfig.whiteLabelCustomHostAPI}/white-label-custom/v{apiVersion}`,
  headers: {},
  paramsSerializer: params => {
    const isSandbox = params.isSandbox

    const encode = !isSandbox || false

    if (isSandbox) delete params.isSandbox

    return qs.stringify(params, { arrayFormat: 'brackets', encode })
  }
})

const whiteLabelCustomRequestAPI = withVersioning(axiosInstance, {
  apiVersion: '1',
  versioningStrategy: VersioningStrategy.UrlPath
})

whiteLabelCustomRequestAPI.interceptors.request.use(
  (config) => {
    config = insertHeaders(config)
    config = insertSellerAndMarketplaceContext(config)

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

whiteLabelCustomRequestAPI.interceptors.response.use(fulfillMiddleware, rejectMiddleware)

export default whiteLabelCustomRequestAPI

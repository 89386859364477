export default {
  recoverAccess: 'users/recover-access',

  checkCredentials: 'auth/signin/check',
  signIn: 'auth/signin/confirm',

  checkToken: 'users/verify-token',

  userPermission: 'users/{userId}/permissions',
  marketPlaces: '',

  userList: 'users',
  userDetail: 'users/{userid}',
  updateUser: 'users/{userid}',
  updatePass: 'users/{userid}/password',
  delUser: 'users/{userid}',
  setProfile: 'users/{userid}/profile',
  inviteUser: 'users?invite',

  createBankAccountToken: 'bank_accounts/tokens',
  removeBankAccount: 'bank_accounts/{bankAccountId}',
  associateBankAccountCustomer: 'bank_accounts',
  listMarketplaceBankAccounts: 'sellers/{sellerId}/bank_accounts',
  bankReceiver: 'sellers/{sellerId}',
  defaultBankCreditIndividual: 'sellers/individuals/{sellerId}',
  defaultBankCreditBusiness: 'sellers/businesses/{sellerId}',
  updateReceivingPolicy: 'sellers/{sellerId}/receiving_policy',
  getReceivingPolicy: 'sellers/{sellerId}/receiving_policy',

  createTransaction: 'transactions',
  getMarketplaceTransactions: 'transactions', // api-query
  getSellerTransactions: 'sellers/{sellerId}/transactions',
  getTransaction: 'transactions/{transactionId}', // api-query
  voidTransaction: 'transactions/{transactionId}/void',
  captureTransaction: 'transactions/{transactionId}/capture',
  getTransactionCancelledLetter: 'transactions/{transactionId}/generate',
  cancelBoleto: 'cancellation/marketplaces/{marketplaceId}/transactions/{transactionId}',

  getMarketplaceTransfers: 'transfers', // api-query
  getSellerTransfers: 'sellers/{sellerId}/transfers',
  getTransferDetails: 'transfers/{transferId}', // api-query
  getTransferAdjustmentsDetails: 'adjustments/{transferId}',
  getTransferHistory: 'transfers/{transferId}/history',
  getTransferRecipientDetails: 'sellers/{recipientId}',
  getTransferSenderDetails: 'sellers/{senderId}',
  getFutureTransfers: 'future-transfers',
  getTransactionsTransfer: 'transfers/{transferId}/transactions',
  createP2PTransfer: 'transfers/{originSellerId}/to/{destinySellerId}',
  createBankAccountTransfer: 'bank_accounts/{bankAccountId}/transfers',

  exportList: 'users/{userId}/exports/{type}/export?{queryString}',
  getExportedList: 'users/{userId}/exports/{type}/list-files',
  getProgressExport: 'users/{userId}/exports/{type}/file/{filename}/progress',
  getRedoExport: 'users/{userId}/exports/{type}/file/{filename}/redo',
  fileExport: 'users/{userId}/exports/{type}/file/{filename}',
  exportListMarkup: 'exports/{type}/export?{queryString}',
  getExportedListMarkup: 'exports/{type}/list-files',
  getProgressExportMarkup: 'exports/{type}/file/{filename}/progress',
  getRedoExportMarkup: 'exports/{type}/file/{filename}/redo',
  fileExportMarkup: 'exports/{type}/file/{filename}',

  getMetrics: 'metrics',
  getMetricsBySeller: 'sellers/{sellerId}/metrics',

  getCustom: 'custom', // zoop-white-label-custom
  getApiSettings: 'api_settings',
  updateCustomColor: 'custom/settings', // zoop-white-label-custom

  // PLANS
  getPlans: 'plans',
  planDetails: 'plans/{planId}',
  getSellersByPlan: 'plans/{planId}/sellers',
  sellerSubscriptions: 'sellers/{sellerId}/subscriptions',
  setSellerToSubscriptions: 'plans/{planId}/subscriptions',
  removeSellerToSubscription: 'subscriptions/{subscriptionId}',

  createSeller: 'sellers/create',

  getSellers: 'sellers', // api-query
  searchSeller: 'sellers', // api-query

  getMarketplaceDetails: '',
  getMarketplaceTree: 'users/{userId}/marketplaces',
  updateBusiness: 'sellers/{businessType}/{businessId}',

  getSellerDetails: 'sellers/{sellerId}',
  getSellerStats: 'balances/current',
  getSellerBlockedBalance: 'balances/blocked',
  deleteSeller: 'sellers/{sellerId}',

  getAddressFromPostalCode: 'https://viacep.com.br/ws/{zipCode}/json',
  getMerchantCodes: 'merchant_category_codes',

  getMarketplaceIdBySlug: 'slugs/{slugName}',
  updateCustomMarketplaceSlug: 'custom/slug',
  getSlugByMarketplaceId: 'custom/slug',

  searchBuyer: 'buyers/search',
  createBuyer: 'buyers',
  updateBuyer: 'buyers/{buyerId}',
  getBuyer: 'buyers/{buyerId}',

  updateSupportData: 'custom/settings', // zoop-white-label-custom

  uploadFile: 'custom/upload/{name}', // zoop-white-label-custom

  getSellerDocuments: 'sellers/{sellerId}/documents',
  uploadSellerDocument: 'sellers/{sellerId}/documents',
  downloadSellerDocument: 'documents/{documentId}/file',

  getWebhooks: 'webhooks',
  getWebhooksEvents: 'events/label',
  addWebhooks: 'webhooks',
  deleteWebhook: 'webhooks/{webhookId}',

  getReceivables: 'transactions/{transactionId}/receivables',
  getReceipts: 'receipts/{receiptId}',
  sendReceiptByEmail: 'receipts/{receiptId}/emails',
  pairPos: 'sellers/{sellerId}/terminals/pairing',

  getSellerBankingBalances: 'entries/banking',
  getSellerPaymentsBalances: 'entries/payments',

  hintViewCheck: 'users/{userId}/hints/check',

  favoriteSellers: 'users/{userId}/favorites/sellers',
  favoriteSellerId: 'users/{userId}/favorites/sellers/{sellerId}',

  favoriteMarketplaces: 'users/{userId}/favorites/marketplaces',
  favoriteMarketplaceId: 'users/{userId}/favorites/marketplaces/{marketplaceId}',

  getBanksList: 'banks',

  getBillingPayment: 'accounts/{accountId}/payments/authorization/{authorizationId}',
  getBillingPaymentReceipt: 'accounts/{accountId}/payments/{paymentId}/receipts',
  getHolderAccounts: 'holders/{sellerId}/accounts',

  getAnticipations: 'anticipations',
  getAnticipationsBySeller: 'anticipations/seller/{sellerId}',
  getAnticipationById: 'anticipations/{anticipationId}',
  getAnticipationsInfo: 'anticipations/seller/{sellerId}/info',
  getAnticipationsReceipt: 'users/{userId}/anticipations/{anticipationId}/receipt',
  createAnticipations: 'anticipations/{prepaymentId}/commit',
  createAnticipationSimulation: 'anticipations',

  getFeatureFlags: 'feature_flags',
  createFeatureFlags: 'feature_flags'

}

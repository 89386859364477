import { Ability, AbilityBuilder } from '@casl/ability'
import groupPermissions from 'src/Constants/GroupPermissions'
import profilePermissions from 'src/Constants/ProfilePermissions'

import { getPermissionsFromGroup } from 'src/Helpers/Permissions'
const ability = new Ability()

export const setDefaultPermissions = () => {
  const { rules } = AbilityBuilder.extract()
  return ability.update(rules)
}

export const setPermissionByProfile = (profileName) => {
  const { rules, can } = AbilityBuilder.extract()
  const permFromProfile = profilePermissions[profileName]
  const allPermissions = getPermissionsFromGroup(permFromProfile, groupPermissions)

  for (let i = 0, x = allPermissions.length; i < x; ++i) {
    let permission = allPermissions[i]

    Object.keys(permission).forEach(action => {
      can([`${action}`], permission[action])
    })
  }

  return ability.update(rules)
}
